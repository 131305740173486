import { FactoryStatus } from "types/factory";
import { Currency, File } from "types/global";
import { SupplierStatus } from "types/supplier";
import { CapReportStatus, SkuFileType, TaskReportResult, TaskStatus } from "types/task";

export enum Concept { //brand
  Core = "CORE",
  Modern = "MODERN",
  BabyAndChild = "Baby & Child",
  Teen = "TEEN",
}

export enum CountryCode {
  US = "1",
  EU = "2",
  UK = "GBR",
  GLB = "GLB",
}

export enum DcCode {
  ODC = "89",
  SFDC = "95",
  LADC = "97",
  MDC = "98",
  BELGIUM_DC = "99",
  DC = "982",
}

export enum SKUStatus {
  A = "A",
  D = "D",
  S = "S",
  Q = "Q",
  X = "X",
  P = "P",
  N = "N",
}

export enum BOMType {
  NA = "NA",
  S = "S",
  BS = "BS",
  C = "C",
  B = "B",
  H = "H",
  M = "M",
}

export enum QuoteStatus {
  Acepted = "accepted",
  Pending = "pending",
  Rejected = "rejected",
}

export interface SkuSupplierInfo {
  // sku supplier info
  vendorNum: string | null; // 9391
  vendorDesc: string | null; // APOLLO ARTS AND CRAFTS
  vendorItemDesc: string | null; // PYRITE MIR 36X48 PYR
  vendorStyle: string | null; // AAC-RH-8
}

export interface CwItemDescription {
  taskStatus?: TaskStatus;
  capStatus?: CapReportStatus;
  completedStatus?: TaskReportResult;
  exportMarket: string | null;
  cwItemDescription?: string | null;
  minPoCancelDate: string | null; // 3/5/2022
  isPrimeSku?: boolean;
  hasGrouping?: boolean;
  isRetest?: boolean;
}

// create sku item
export interface SkuItem extends SkuSupplierInfo {
  id?: number;

  isAFLPCompleted?: boolean;
  isQaCompleted?: boolean;
  isCurrent?: boolean;
  isPrimeSku?: boolean;

  wicNumber: string | null; // 10097378PYR

  skuStatus: SKUStatus | null; // A

  cwItemDescription: string | null;
  cwItemDescriptions: CwItemDescription[];

  dcList: string | null; // 099,088
  concept: string | null; // CORE
  collection: string | null; // PYRITE MIRRORS
  frame: string | null; // PYRITE
  colorCode: string | null; // PTR
  sizeCode: string | null;

  createTime?: string | null; // 3/5/2022
  startSellingDate: string | null; // 3/5/2022
  skuCreateDate: string | null; // 3/5/2022

  buyerDesc: string | null; // DECOR

  deptNum: string | null; // 4000
  deptDesc: string | null; // HOME ACCESSORIES
  subDeptDesc: string | null; // MIRRORS

  countryOfOrigin: string | null; // IND
  fabric: string | null; // PYRITE
  fill: string | null;
  finish: string | null;
  function: string | null;
  bom: string | null;
  countryCodeList: string | null; //1;GBR

  hasProp65: boolean;
  prop65Remark: string;
}

export interface MasterWicFile {
  fileId: number;
  type: SkuFileType;
}

export interface AttachmentFile {
  file: File;
  type: SkuFileType;
}

export interface ProductImageFile {
  file: File;
  type: SkuFileType;
  id?: number;
  masterWics?: any;
}

// all sku list item
export interface SkuListItem extends SkuSupplierInfo {
  id?: number;

  skuStatus: SKUStatus | null; // A

  wicNumber: string | null; // 10097378PYR

  cwItemDescription: string | null;
  glbMinPoCancelDate: string | null;
  usCwItemDescription: string | null;
  euCwItemDescription: string | null;
  ukCwItemDescription: string | null;
  taskRequirements: CwItemDescription[];

  usValidityDate: string | null;
  euValidityDate: string | null;
  ukValidityDate: string | null;

  usStatus: TaskStatus | null;
  euStatus: TaskStatus | null;
  ukStatus: TaskStatus | null;
  glbStatus: TaskStatus | null;
  qcStatus: TaskStatus | null;

  usTaskStatus: TaskStatus | null;
  euTaskStatus: TaskStatus | null;
  ukTaskStatus: TaskStatus | null;
  qcTaskStatus: TaskStatus | null;

  usMinPoCancelDate: string | null;
  euMinPoCancelDate: string | null;
  ukMinPoCancelDate: string | null;

  ukCompletedStatus: TaskReportResult;
  euCompletedStatus: TaskReportResult;
  usCompletedStatus: TaskReportResult;
  glbCompletedStatus: TaskReportResult;
  qcCompletedStatus: TaskReportResult;

  skuCreateDate?: string | null; // 3/5/2022
  startSellingDate: string | null; // 3/5/2022

  buyerDesc: string | null; // DECOR

  deptNum: string | null; // 4000
  deptDesc: string | null; // HOME ACCESSORIES
  subDeptDesc: string | null; // MIRRORS
  complianceDocRemark?: string | null;

  countryOfOrigin: string | null; // IND
  countryCodeList: string | null; //1;GBR

  concept: string | null; // CORE
  collection: string | null; // PYRITE MIRRORS
  frame: string | null; // PYRITE
  colorCode: string | null; // PTR
  sizeCode: string | null;
  fabric: string | null; // PYRITE
  fill: string | null;
  finish: string | null;
  function: string | null;
  bom: string | null;

  dcList: string | null; // 099,088

  masterWicFiles: MasterWicFile[];
  productImages: ProductImageFile[];
  attachments: AttachmentFile[];

  hasGrouping: boolean;
  hasProp65: boolean;
  prop65Remark: string;
}

// sku detail summary
export type ItemDetailSummary = Partial<SkuListItem>;

// sku detail task list
export interface ItemTask {
  id?: number;
  isPrimeSku?: boolean;
  isRetest?: boolean;
  cwItemDescription: CwItemDescription["cwItemDescription"];
  exportMarket: CwItemDescription["exportMarket"];
  validityDate: string | null;
  taskStatus: TaskStatus;
  capStatus: CapReportStatus;
  completedStatus: TaskReportResult;
}

export interface TestPlan {
  id: number;
  itemName: string;
  quantity: number;
  subTotal: number;
  discount: number;
  surcharge: number;
  unitPrice: number;
  hasReport?: boolean;
}

export interface Quotation {
  currency: Currency;
  deliveryNote: string | null;
  expectedCompletionDate: string | null;
  id: number;
  isPackagePrice: boolean;
  isPrepayment: boolean;
  isRetest?: boolean | null;
  lab: string | null;
  lastUpdated: string | null;
  packagePrice: number | null;
  packageRemark: string | null;
  remarks: string | null;
  sampleSize: string | null;
  status: string | null; //pending
  subTotal: number;
  tat: string | null;
  testPlans: TestPlan[] | [];
  totalPrice: number;
  vat: number;
}

export interface FilterListItem {
  brand: any[];
  category: any[];
  exportMarket: any[];
  factory: any[];
  progress: any[];
  status: any[];
  supplier: any[];
}

export interface FilterLogItem {
  action: any[];
  actor: any[];
}

export interface TaskSupplier {
  id: number;
  name: string | null;
  email: string | null;
  supplierNo: string | null;
  status: SupplierStatus;
}
export interface TaskFactory {
  id: number;
  country: string | null;
  state: string | null;
  address: string | null;
  name: string | null;
  factoryNo: string | null;
  status: FactoryStatus | null;
  isReal: boolean;
}
export interface RejectApplication {
  rejectReason: string;
  rejectRemark: string;
}

export interface ItemInvoice {
  address: string | null;
  bankAddress: string | null;
  bankName: string | null;
  contactNumber: string | null;
  currency: Currency;
  deliveryNote: string | null;
  enterpriseName: string | null;
  expectedCompletionDate: string | null;
  id: number;
  invoiceType: string;
  isResume: boolean | null;
  isPackagePrice: boolean;
  isPrepayment: boolean;
  lab: string | null;
  lastUpdated: string | null;
  packagePrice: number | null;
  packageRemark: string | null;
  payerCurrency: Currency;
  payerCompanyAddress: string | null;
  payerCompanyName: string | null;
  payerContactPerson: string | null;
  payerEmail: string | null;
  payerPhoneNumber: string | null;
  reasonOption: string | null;
  reasonRemarks: string | null;
  remarks: string | null;
  sampleSize: string | null;
  deliveryNotes: string | null;
  status: string | null;
  subTotal: number;
  tat: string | null;
  taxRegistrationCode: string | null;
  testPlans: TestPlan[] | [];
  totalPrice: number | 0;
  vat: number | 0;
  paymentStatus: string | null;
}

export interface ItemSupplier {
  address: string;
  contactEmail: string;
  name: string;
  contactNumber: string;
  contactPerson: string;
  supplierCode: string;
}

export interface ItemFactory {
  factoryCode: string;
  address1: string;
  address2: string;
  contactEmail: string;
  contactName: string;
  name: string;
  contactNumber: string;
  aisFactoryInformation: [];
  contactPerson?: string;
}

export interface ItemSupplierFactory {
  factory: ItemFactory;
  supplier: ItemSupplier;
  masterWicId: number;
}
